import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import LayoutSmall from 'layouts/LayoutSmall';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { useActivateDemoMutation } from '../../generated/graphql';
import { Store } from '../../model/store/Store';
import { DOMAIN_CREATE, INDEX } from '../../routes';

import cls from './Domain.module.scss';

export const DomainActivation = observer(() => {

  const { t } = useTranslation();
  const store = useInstance(Store);
  const navigate = useNavigate();

  const [activateDemo, {loading}] = useActivateDemoMutation()

  const menuDomains: MenuProps['items'] = _.map(store.domain.domains, domain => ({
    key: domain.id,
    label: domain.name,
    onClick: () => {
      store.domain.selectDomain(domain.id);
      navigate(INDEX);
    },
    icon: store.domain.selectedDomain === domain.id && <CheckOutlined />
  }));

  menuDomains.push({
    key: 'new',
    label: t('Add'),
    onClick: () => navigate(DOMAIN_CREATE),
    icon: <PlusOutlined />
  });

  const onActivateDemo = async () => {
    await activateDemo({
      variables: {
        id: store.domain.selectedDomain!
      },
      onCompleted: (data) => {
        if (data.activateDemo?.done) {
          navigate(INDEX)
        }
      },
    })
  }

  return (
    <LayoutSmall title={t('Activation')}>

      <div className={cls.page}>

        <Dropdown menu={{ selectedKeys: [store.domain.selectedDomain!], items: menuDomains }} trigger={['click']}>
          <Button shape={'round'} loading={!store.domain.loaded}>{store.domain.loaded && store.domain.currentDomain().name}</Button>
        </Dropdown>

        {store.domain.currentDomain().isAdmin ?
          <>
            <Button block type={'primary'} className={cls.activateButton} disabled>
              <span className={cls.bold}>{t('By certificate number')}</span>
              {t('It was issued at the time of purchase')}
            </Button>
            <Button block disabled className={cls.activateButton}>
              <span className={cls.bold}>{t('By bank card')}</span>
              {t('Temporarily unavailable')}
            </Button>
            <Button block className={cls.activateButton} onClick={onActivateDemo} disabled={store.domain.currentDomain().demoUsed} loading={loading}>
              <span className={cls.bold}>{t('Demo access')}</span>
              {t('Free for 2 weeks')}
            </Button>
          </>
          :
          <>
            <div className={cls.errorTitle}><LockOutlined/> {t('Workspace blocked')}</div>
            <div>{t('Only Admin can unblock Workspace')}</div>
          </>
        }

      </div>

    </LayoutSmall>
  );
});


