import React, {FC} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate} from 'react-router-dom';
import LayoutSmall from 'layouts/LayoutSmall';

import { Login } from '../../components/Auth/Login/Login';
import { INDEX } from '../../routes';

export const LoginPage: FC = () => {

    const {t} = useTranslation()
    const navigate = useNavigate();

    return (
        <LayoutSmall title={t('Entrance')}>
            
                <Login afterLogin={() => navigate(INDEX)}/>

        </LayoutSmall>
    );
};