import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Link } from 'react-router-dom';
import type {MenuProps} from 'antd';
import { Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';

import { Store } from '../model/store/Store';
import { INDEX, LOGOUT } from '../routes';

import image from './bg.jpg';
import cd2c from './cd2c.svg'
import saturn from './saturn.svg'

import cls from './layouts.module.scss';

interface LayoutSmallProps {
  title: string
  children?: React.ReactNode;
}

const LayoutSmall = observer(({ title, children }: LayoutSmallProps) => {

  const {auth} = useInstance(Store)
  const {t} = useTranslation()

  const menuAvatar: MenuProps['items'] = [
    {
      key: 'logout',
      label: (<Link to={LOGOUT}>{t('Logout')}</Link>),
    },
  ];

  return (
    <div className={cls.layout} style={{ backgroundImage: `url(${image})` }}>
      <div className={cls.wrapper}>
        <div className={cls.app}>
          <Link to={INDEX}><div className={cls.logo}><img src={saturn} alt={'saturn'}/></div></Link>
          <div className={cls.name}>
            {auth.hasAuthToken
              ?
              <Dropdown menu={{items: menuAvatar}} trigger={['click']} placement={'bottom'}>
                <span>{auth.data?.username}</span>
              </Dropdown>
              :
              'Saturn'
            }
          </div>
        </div>
        <div className={cls.title}>
          {title}
        </div>
        <div className={cls.content}>
          <div className={cls.fill}>
            {children}
          </div>
        </div>
        <div className={cls.cd2c}>
          <img src={cd2c} alt={'CD2C'}/>
        </div>
      </div>
    </div>
  )
});

export default LayoutSmall;