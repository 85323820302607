/* eslint-disable */
import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import i18next from 'i18next';

import ru from './locales/ru/translation.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: 'ru', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: 'ru',
        debug: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

i18next.addResourceBundle('ru', 'translation', ru);

export default i18n;