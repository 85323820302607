import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Select } from 'antd'
import { useTeamQuery } from 'generated/graphql';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

export const SelectTeam = observer((props:any) => {

  const {t} = useTranslation()
  const store = useInstance(Store);

  const query = useTeamQuery({
    variables: {
      domain: store.domain.selectedDomain!
    }
  })

  const options = _.map(query.data?.team?.data, item => ({
    value: item.id!,
    label: item.username!,
  }))

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...props} mode={'multiple'} options={options} placeholder={t('No Users')}/>
  )
})