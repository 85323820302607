import { type Instance, type SnapshotIn, type SnapshotOut, types as t } from 'mobx-state-tree';

import {AuthStore} from './AuthStore';
import {DomainStore, initialDomainStore} from './DomainStore';

export const RootStore = t
    .model({
        hydrated: t.optional(t.boolean, false), // метка что состояние хранилища восстановлено из сохраненного в localstorage
        auth: t.optional(AuthStore, {}),
        domain: t.optional(DomainStore, initialDomainStore),
    })
    .actions(self => ({
        setHydrated: (value: boolean) => {
            self.hydrated = value;
        },
    }));

export interface IRootStoreModel extends Instance<typeof RootStore> {
}

export interface IRootStoreModelSnapshotIn extends SnapshotIn<typeof RootStore> {
}

export interface IRootStoreModelSnapshotOut extends SnapshotOut<typeof RootStore> {
}

