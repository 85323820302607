import React from 'react';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTimersQuery } from 'generated/graphql';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { Tag } from '../Board/Tag';

import cls from './Timers.module.scss'
dayjs.extend(duration)
interface IReports {
  taskId: string
}


export const Timers = observer(({taskId}:IReports) => {

  // const store = useInstance(Store);

  // const {t} = useTranslation()

  const timers = useTimersQuery({
    variables: {
      filters: {
        task: {id: {eq: taskId}}
      },
      pagination: {
        limit: 1000,
      },
    },
    pollInterval: 1000,
  })

  const totalSeconds: number = _.reduce(timers.data?.timers?.data, function(sum, item) {
    if (item.attributes?.finishedAt !== null) {
      return sum + dayjs(item.attributes?.finishedAt).diff(dayjs(item.attributes?.createdAt), 'seconds' )*1000
    } else {
      return sum
    }
  }, 0);

  return (
    <div className={cls.reports}>
      {timers.loading && <Skeleton.Input block active/>}

      <div className={cls.grid}>
        {_.map(timers.data?.timers?.data, timer => (
          <React.Fragment key={timer.id}>
            <div className={cls.user}>
              <Tag data={{
                id: timer.attributes?.user?.data?.id!,
                name: timer.attributes?.user?.data?.attributes?.username!,
                color: timer.attributes?.user?.data?.attributes?.color!,
              }}/>
            </div>
            <div className={cls.time}>{timer.attributes?.finishedAt ? dayjs.duration(dayjs(timer.attributes.finishedAt).diff(dayjs(timer.attributes.createdAt), 'seconds' )*1000).format('HH:mm:ss') : '🕑'}</div>
            <div className={cls.comment}>{dayjs(timer.attributes?.createdAt).format('DD.MM.YYYY HH:mm')} — {timer.attributes?.finishedAt && dayjs(timer.attributes?.finishedAt).format('DD.MM.YYYY HH:mm')}</div>
          </React.Fragment>
        ))}

        <div className={cls.user}></div>
        <div className={cls.time}><b>{dayjs.duration(totalSeconds).format('HH:mm:ss')}</b></div>
        <div className={cls.comment}></div>

      </div>


    </div>
  )

})