import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Skeleton, Tooltip } from 'antd';
import { useCreateReportMutation, useReportsQuery } from 'generated/graphql';
import _, { toNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import { Minutes } from 'components/Form/Minutes/Minutes';

import { MinutesFormat } from '../../shared/MinutesFormat';
import { Tag } from '../Board/Tag';

import cls from './Reports.module.scss'

interface IReports {
  taskId: string
}

interface IForm {
  minutes: number
  comment: string
}

export const Reports = observer(({taskId}:IReports) => {

  const store = useInstance(Store);

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm()
  const {t} = useTranslation()

  const [create] = useCreateReportMutation()

  const reports = useReportsQuery({
    variables: {
      filters: {
        task: {id: {eq: taskId}}
      }
    }
  })

  const onAdd = async (values:IForm) => {

    await create({
      variables: {
        data: {
          minutes: values.minutes,
          comment: values.comment,
          task: taskId,
          domain: store.domain.selectedDomain,
        }
      },
      onCompleted: () => {
        setOpen(false)
        form.resetFields()
        reports.refetch()
      }
    })
  }

  const onClose = () => {
    setOpen(false)
    form.resetFields()
  }

  const totalMinutes:number = _.reduce(reports.data?.reports?.data, function (sum, item) {return sum + toNumber(item.attributes?.minutes!)}, 0)

  return (
    <div className={cls.reports}>
      {reports.loading && <Skeleton.Input block active/>}

      <div className={cls.grid}>
        {_.map(reports.data?.reports?.data, report => (
          <React.Fragment key={report.id}>
            <div className={cls.user}>
              <Tag data={{
                id: report.attributes?.author?.data?.id!,
                name: report.attributes?.author?.data?.attributes?.username!,
                color: report.attributes?.author?.data?.attributes?.color!,
              }}/>
            </div>
            <div className={cls.time}>{MinutesFormat(report.attributes?.minutes!)}</div>
            <div className={cls.comment}>
              <Tooltip title={report.attributes?.comment} placement={'topLeft'}>
                {report.attributes?.comment}
              </Tooltip>
            </div>
          </React.Fragment>
        ))}

        <div className={cls.user}></div>
        <div className={cls.time}><b>{MinutesFormat(totalMinutes)}</b></div>
        <div className={cls.comment}></div>

      </div>

      <Button type={'text'} icon={<PlusOutlined/>} block onClick={() => setOpen(true)}/>

      <Modal open={open} onCancel={onClose} footer={null} closable={false} width={360}>
        <div className={cls.title}>{t('New report')}</div>
        <Form layout={'vertical'} onFinish={onAdd} form={form}>
          <Form.Item name={'minutes'}
                     // label={t('Hours : Minutes')}
                     rules={[
                       {
                         required: true,
                         message: t('Please enter the data') || '',
                       },
                     ]}
                     initialValue={0}
          >
            <Minutes/>
          </Form.Item>
          <Form.Item name={'comment'}
                     // label={t('Comment')}
                     rules={[
                       {
                         required: true,
                         message: t('Please enter the data') || '',
                       },
                     ]}
                     initialValue={''}
          >
            <Input.TextArea bordered={true} autoSize={{minRows:4, maxRows:6}} placeholder={t('Comment')!}/>
          </Form.Item>
          <Form.Item>
            <Button htmlType={'submit'} type={'primary'} block icon={<PlusOutlined />}>{t('Add')}</Button>
          </Form.Item>
        </Form>

      </Modal>

    </div>
  )

})