import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useCompaniesQuery, useEpicsQuery, useTeamQuery } from 'generated/graphql';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import { Filter } from 'components/Filter/Filter';

interface IFilters {
  selectedEpics: string[] | null;
  selectedClients: string[] | null;
  selectedTeam: string[] | null;
  onChangeEpics: (data: string[] | null) => void;
  onChangeClients: (data: string[] | null) => void;
  onChangeTeam: (data: string[] | null) => void;
}

export const Filters = observer(({ selectedEpics, selectedClients, selectedTeam, onChangeEpics, onChangeTeam, onChangeClients }: IFilters) => {

  const { t } = useTranslation();
  const store = useInstance(Store);


  const epics = useEpicsQuery({
    variables: {
      filters: {
        domain: {id: {eq: store.domain.selectedDomain}},
      },
      pagination: { limit: 100 }
    }
  });

  const clients = useCompaniesQuery({
    variables: {
      filters: {
        enabled: {eq: true},
        domain: {id: {eq: store.domain.selectedDomain}},
      },
      pagination: { limit: 100 },
    }
  })

  const team = useTeamQuery({
    variables: {
      domain: store.domain.selectedDomain!
    }
  })

  const epicsData =  _.map(epics.data?.epics?.data, item => ({id: item.id!, name: item.attributes?.name!}))
  const clientsData =  _.map(clients.data?.companies?.data, item => ({id: item.id!, name: item.attributes?.name!}))
  const teamData =  _.map(team.data?.team?.data, item => ({id: item.id!, name: item.username!}))

  const onEpicChanges = (data: string[]|null) => {
    onChangeEpics(data)
  }
  const onClientsChanges = (data: string[]|null) => {
    onChangeClients(data)
  }
  const onTeamChanges = (data: string[]|null) => {
    onChangeTeam(data)
  }

  return (
    <>
      <Filter title={t('Client')} data={clientsData} selected={selectedClients || []} onChance={onClientsChanges}/>
      <Filter title={t('Team')} data={teamData} selected={selectedTeam || []} onChance={onTeamChanges}/>
      <Filter title={t('Epic')} data={epicsData} selected={selectedEpics || []} onChance={onEpicChanges}/>
    </>
  );
})