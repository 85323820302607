
export function MinutesFormat (minutes:number) {

  const H = Math.floor(minutes / 60)
  const M = minutes % 60

  const HH = H < 10 ? `0${H}` : H
  const MM = M < 10 ? `0${M}` : M

  return `${HH}:${MM}`
}