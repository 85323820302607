import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs'
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { Store } from '../../model/store/Store';
import { IStatus } from '../../shared/status';

import { BoardData } from './Board';
import { Card } from './Card';

import cls from './Board.module.scss';

export interface IColumn {
  loading?: boolean
  status: IStatus
  data: BoardData[]
  onCreate: (status:string) => void
  onCardOpen: (id:string) => void
  onCardDrag: (id:string) => void
  onColumnDrop: (status:string) => void
}

export const Column = observer(({loading, status, data, onCreate, onCardOpen, onCardDrag, onColumnDrop} : IColumn) => {

  const [isOver, setIsOver] = useState(false);
  const [wipLimit, setWipLimit] = useState(false)

  const { t } = useTranslation();

  const {auth} = useInstance(Store)

  const [time, setTime] = useState(dayjs());
  useEffect(() => {
    const interval = setInterval(() => setTime(dayjs()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);



  function handleDragOver(e:any) {
    if (e.dataTransfer.types[0] === 'text/plain') {
      setIsOver(true);
      if (status.focus) {
        // если есть мои задачи...
        if (data.length > 0) {
          setWipLimit(true)
        }
      }
      e.preventDefault();
    }
    // console.log('over', e);
  }

  function handleDrop(e:any) {
    const dataJSON = e.dataTransfer.getData('text/plain');
    let data;
    try {
      data = JSON.parse(dataJSON);
    } catch {
      //null
    }
    if (data && data.type === 'card') {
      setIsOver(false)
      setWipLimit(false)
      onColumnDrop(status.name)
    }
    // console.log('drop', e);
  }

  function handleDragLeave() {
    setIsOver(false);
    setWipLimit(false)
  }

  let calcDate = undefined
  if (status.focus && auth.data?.focusedAt) {
     calcDate = dayjs.duration((time.unix() - dayjs(auth.data?.focusedAt).unix()) * 1000)
  }

  return (
    <div className={cls.column}
         onDragOver={handleDragOver}
         onDrop={handleDrop}
         onDragLeave={handleDragLeave}
    >

      <div className={cn([cls.dragArea, isOver && cls.dragged, wipLimit && cls.wipLimit])}/>
      {status.focus && <div className={cls.focus}/>}

      <div className={cls.title} style={{color: status.color || '#FFFFFF' , backgroundColor: status.bgColor || '#202020'}}>
        <span>{t(status.name)}</span>
        <span>{data.length}</span>
      </div>

      {loading
        ?
        <Skeleton.Input block active/>
        :
        _.map(data, card => (<Card key={card.id} data={card} onCardOpen={() => onCardOpen(card.id)} onCardDrag={() => onCardDrag(card.id)}/>))
      }

      {status.focus ||
      <Button shape={'round'} type={'text'} block icon={<PlusOutlined/>} onClick={() => onCreate && onCreate(status.name)}/>
      }

      {calcDate &&
        <div className={cls.timer}>{calcDate.format('HH:mm:ss')}</div>
      }

    </div>
  )

})