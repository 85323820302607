import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, message} from 'antd';
import {useCreateDomainMutation} from 'generated/graphql';
import LayoutSmall from 'layouts/LayoutSmall';
import {observer} from 'mobx-react-lite';
import { IFunc, ITask } from 'model/store/DomainStore';
import {Store} from 'model/store/Store';
import { DOMAIN_ACTIVATION } from 'routes';

import { workspaceRegex } from '../../shared/regex';

interface IFormState {
    name: string,
}

const CreateDomainPage: FC = () => {

    const {domain} = useInstance(Store);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const [createDomain, createDomainData] = useCreateDomainMutation()

    const onSubmit = async (values: IFormState) => {
        await createDomain({
            variables: {
                data: {
                    name: values.name
                }
            },
            onCompleted: (res: any) => {
                const id = res?.createDomain?.data.id;
                const name = res?.createDomain?.data.attributes.name;
                domain.pushDomain({
                    id: id as string,
                    name: name as string,
                    isAdmin: true,
                    isActive: true,
                    blocked: res?.createDomain?.data.attributes.blocked,
                    activeUsers: res?.createDomain?.data.attributes.activeUsers,
                    activeTill: res?.createDomain?.data.attributes.activeTill,
                    demoUsed: res?.createDomain?.data.attributes.demoUsed,
                    taskConfig: res?.createDomain?.data.attributes.taskConfig as ITask[],
                    funcConfig: res?.createDomain?.data.attributes.funcConfig as IFunc,
                })
                navigate(DOMAIN_ACTIVATION);
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            },
        })
    }

    const [form] = Form.useForm<IFormState>();

    const initialFormState: IFormState = useMemo(() => ({
            name: '',
        }), []);

    return (
        <LayoutSmall title={t('Workspace')}>
            
            <Form name={'domain'}
                layout='vertical'
                  initialValues={initialFormState}
                  form={form}
                  onFinish={onSubmit}
                  scrollToFirstError
            >

                <Form.Item name='name' label={t('Workspace name')}
                           validateFirst
                           rules={[
                               {required: true, message: `${t('Required')}`},
                               { min: 4, message: `${t('Min 4 symbols')}` },
                               { pattern: workspaceRegex, message: t('Only latin letters and digits')! },
                           ]}

                >
                    <Input/>
                </Form.Item>

                    <Button block type='primary' htmlType="submit" loading={createDomainData.loading}>{t('Create')}</Button>

            </Form>

        </LayoutSmall>
    );
};

export default observer(CreateDomainPage);



