import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Select } from 'antd'
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { Store } from '../../../model/store/Store';
import { IStatus } from '../../../shared/status';

export const SelectStatus = observer((props:any) => {

  const {t} = useTranslation()
  const { domain } = useInstance(Store)

  const currentDomain:any = domain.currentDomain()
  const status:IStatus[] = currentDomain.taskConfig

  const options = _.map(status, item => ({
    value: item.name,
    label: t(item.name),
  }))

  // options.push({
  //     value: null,
  //     label: <span>{t('No epic')}</span>,
  // })

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...props} options={options} placeholder={t('No Status')}/>
  )
})
