import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useNavigate, useParams } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { Button, Divider, Skeleton } from 'antd';
import LayoutSmall from 'layouts/LayoutSmall';
import { observer } from 'mobx-react-lite';

import { Login } from '../../components/Auth/Login/Login';
import { useAcceptInviteMutation, useInviteQuery } from '../../generated/graphql';
import { Store } from '../../model/store/Store';
import { INDEX, REGISTER } from '../../routes';

import cls from './Invite.module.scss';

export const Invite = observer(() => {

  const [onWait, setOnWait] = useState<boolean>(false)

  const {UID} = useParams()
  const {t} = useTranslation()
  const { auth, domain } = useInstance(Store)
  const navigate = useNavigate()
  const [acceptInvite] = useAcceptInviteMutation()

  const invite = useInviteQuery({
    variables: {
      id: UID!,
    },
    onError: (error) => {
      if (error.message === 'Not found') {
        navigate(INDEX)
      }
    }
  })

  const domainId:string = invite.data?.invite?.data?.attributes?.domain?.data?.id!

  const onAcceptInvite = async () => {
    setOnWait(true)
    await acceptInvite({
      variables: {id: UID!},
    })
  }

  useEffect(() => {
    // ждем когда появится новый домен в сторе,
    // идем на главную
    if (domain.hasDomain(domainId)) {
      domain.selectDomain(domainId)
      navigate(INDEX)
    }
  }, [domain, domainId, navigate])

  if (invite.loading) {
    return <Skeleton active/>
  }

  if (!auth.hasAuthToken) {

    return (
      <LayoutSmall title={t('Invite to {{Workspace}}', {Workspace: invite.data?.invite?.data?.attributes?.domain?.data?.attributes?.name})}>
        <div className={cls.text}>
          {t('Log in to accept the invitation')}
        </div>
        <Login />
        <Divider/>
        <div className={cls.text}>
          {t('Don\'t have an account yet?')}
        </div>
        <Button block onClick={() => navigate(REGISTER, {state: {
            email: invite.data?.invite?.data?.attributes?.email,
            backTo: `/invite/${UID}`
          }})}>{t('Registration')}</Button>
      </LayoutSmall>
    )

  }

  return (
    <LayoutSmall title={t('Invite to {{Workspace}}', {Workspace: invite.data?.invite?.data?.attributes?.domain?.data?.attributes?.name})}>
      <div className={cls.text}>
        {t('You have been invited to the Workspace')}
      </div>
      {invite.data?.invite?.data?.attributes?.email! !== auth.data?.email! &&
        <div className={cls.warning}>
          <div className={cls.icon}>
            <MailOutlined />
          </div>
          {t('The email address to which the invitation was sent and yours do not match. But you can still accept the invitation.')}
        </div>
      }
      <Button size={'large'} type={'primary'} block onClick={onAcceptInvite} loading={onWait}>{t('Accept Invitation')}</Button>
    </LayoutSmall>
  )

})