import { hslToHex } from 'features/GenerateColor/GenerateColor';
import _ from 'lodash';

export const colors: string[] = [];

for (let l = 6; l >0; l--) {
  for (let h = 0; h < 10; h++) {
    colors.push(hslToHex(h * 36, 30, l * 6 + 14));
  }
}

export const presets = [{
  label: null,
  colors
}];

export const random = () => colors[_.random(0, colors.length - 1)];