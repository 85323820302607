import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Button, Form, Input, message } from 'antd';
import { useLoginMutation } from 'generated/graphql';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

interface IFormState {
  identifier: string,
  password: string,
}

interface ILogin {
  afterLogin?: () => void;
}

export const Login = observer(({ afterLogin }: ILogin) => {

  const [loginMutation] = useLoginMutation();
  const store = useInstance(Store);
  const { t } = useTranslation();

  const onSubmit = async (values: IFormState) => {
    await loginMutation({
      variables: {
        input: {
          identifier: values.identifier,
          password: values.password
        }
      },
      onCompleted: (data: any) => {
        const jwt = data?.login.jwt;
        const userId = data?.login.user.id;
        store.auth.setTokenAndId(jwt, userId);
        store.domain.clearState();
        if (afterLogin) {
          afterLogin();
        }
      },
      onError: (error) => {
        message.error(error.message);
      }
    });
  };

  return (

    <Form layout="vertical"
          onFinish={onSubmit}
          scrollToFirstError
    >

      <Form.Item name="identifier" label={t('Email')}
                 rules={[
                   { required: true, message: t('Required')! },
                   { type: 'email', message: t('Invalid format')! }
                 ]}
      >
        <Input placeholder="example@site.com" />
      </Form.Item>

      <Form.Item name="password" label={t('Password')}
                 rules={[
                   { required: true, message: `${t('Required')}` }
                 ]}
      >
        <Input.Password />
      </Form.Item>

      <Button block htmlType={'submit'} type="primary">{t('Login')}</Button>

    </Form>

  );
})