import { TaskFiltersInput } from 'generated/graphql';

export const arrayToUrl = (data:string[]) => {
  let result = ''
  for (const item of data) {
    result += `${item}-`
  }
  return result
}

export const urlToArray = (value:string) => value.split('-').slice(0,-1) as string[]

export interface IIndexFilters {
    clients: string[] | null
    team: string[] | null
    epics: string[] | null
}

export const defaultIndexFilter:IIndexFilters = {
  clients: null,
  team: null,
  epics: null,
}

export const isChangedFilter = (filter: IIndexFilters) => {
    if (filter.clients !== defaultIndexFilter.clients) return true
    if (filter.team !== defaultIndexFilter.team) return true
    if (filter.epics !== defaultIndexFilter.epics) return true
}

export const filtersToSearchParams = (filter: IIndexFilters) => ({
        ...(filter.clients && {clients: arrayToUrl(filter.clients)}),
        ...(filter.team && {team: arrayToUrl(filter.team)}),
        ...(filter.epics && {epics: arrayToUrl(filter.epics)}),
    })

export const searchParamsToFilters = (params: URLSearchParams) => ({
  clients: params.get('clients') ? urlToArray(params.get('clients')!) : defaultIndexFilter.clients,
  team: params.get('team') ? urlToArray(params.get('team')!) : defaultIndexFilter.team,
  epics: params.get('epics') ? urlToArray(params.get('epics')!) : defaultIndexFilter.epics,
}) as IIndexFilters

export const filtersToQuery = (filter: IIndexFilters) => ({
  ...(filter.clients && {company: {id: {in: filter.clients}}}),
  ...(filter.team && {users: {id: {in: filter.team}}}),
  ...(filter.epics && {epic: {id: {in: filter.epics}}}),
}) as TaskFiltersInput

