import { useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Space, Spin } from 'antd';
import _, { toNumber } from 'lodash';

export const Minutes = (props: any) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);

  const Calculate = _.debounce((H: number, M: number) => {
    const total = (H * 60) + M;
    setHour(Math.floor(total / 60));
    setMinute(total % 60);
    setLoading(false)
    props.onChange(total);
  }, 1000);

  // eslint-disable-next-line
  const debounceCalculate = useCallback((H: number, M: number) => Calculate(H, M), []);

  const onChangeHour = (value: number) => {
    debounceCalculate(toNumber(value), minute);
    setHour(toNumber(value));
    setLoading(true)

  };
  const onChangeMinute = (value: number) => {
    debounceCalculate(hour, toNumber(value));
    setMinute(toNumber(value));
    setLoading(true)
  };

  const optionsH = [
    { value: '0' },
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' }
  ];

  const optionsM = [
    { value: '0' },
    { value: '15' },
    { value: '30' },
    { value: '45' }
  ];


  return (
      <Space>
        <AutoComplete options={optionsH} value={hour} style={{ width: 60 }} onChange={onChangeHour}/>
        :
        <AutoComplete options={optionsM} value={minute} style={{ width: 60 }} onChange={onChangeMinute} />
        {loading && <Spin indicator={<LoadingOutlined/>}/>}
      </Space>
  );
};