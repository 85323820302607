import cn from 'classnames';

import { ITag } from './Board';

import cls from './Tag.module.scss'

interface ITagProps {
  data: ITag
  big?: boolean
}

export const Tag = ({data, big}:ITagProps) => (
  <span className={cn([cls.tag, big && cls.big])} style={{backgroundColor: data.color || '#651F71'}}>
    {data.name}
  </span>
)