import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Link, useNavigate } from 'react-router-dom';
import { CheckOutlined, DatabaseOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Avatar, Button,Dropdown } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';
import { ARCHIVE, DOMAIN_ACTIVATION, DOMAIN_CREATE, INDEX, LOGOUT, SETTINGS } from 'routes';

import img from './Saturn_Logo.svg'

import cls from './Header.module.scss'

interface IHeader {
  filters?: ReactNode
}

export const Header = observer(({filters}: IHeader) => {

  const store = useInstance(Store);
  const {t} = useTranslation()
  const navigate = useNavigate()

  const menuAvatar: MenuProps['items'] = [
    {
      key: 'logout',
      label: (<Link to={LOGOUT}>{t('Logout')}</Link>),
    },
  ];

  const menuDomains: MenuProps['items'] = _.map(store.domain.domains, domain => ({
    key: domain.id,
    label: domain.name,
    onClick: () => {
      store.domain.selectDomain(domain.id)
      navigate(INDEX)
    },
    icon: store.domain.selectedDomain === domain.id && <CheckOutlined/>

  }))

  menuDomains.push({
    key: 'new',
    label: t('Add'),
    onClick: () => navigate(DOMAIN_CREATE),
    icon: <PlusOutlined/>
  })

  if (store.domain.isAdmin()) {
    menuDomains.push({
      key: 'settings',
      label: t('Settings'),
      onClick: () => navigate(SETTINGS),
      icon: <SettingOutlined/>
    })
  }

  menuDomains.push({
    key: 'archive',
    label: t('Archive'),
    onClick: () => navigate(ARCHIVE),
    icon: <DatabaseOutlined/>
  })



  const {isAdmin, activeTill} = store.domain.currentDomain()
  const expireAt = activeTill ? dayjs(activeTill) : dayjs().add(1, 'year')
  const diffDays = expireAt.diff(dayjs(), 'day')

  return (
    <header className={cls.wrapper}>

      {isAdmin && (diffDays < 14 && diffDays >= 0) &&
      <div className={cn([cls.banner, cls.warning])}>
        <div>{t('Expire at {{date}}', {date: expireAt.format('DD MMM YYYY')})}</div>
        <div><Button onClick={() => navigate(DOMAIN_ACTIVATION)}>{t('Renew')}</Button></div>
      </div>
      }

      {isAdmin && diffDays < 0 &&
        <div className={cn([cls.banner, cls.error])}>
          <div>{t('Expired at {{date}}', {date: expireAt.format('DD MMM YYYY')})}</div>
          <div><Button onClick={() => navigate(DOMAIN_ACTIVATION)}>{t('Renew')}</Button></div>
        </div>
      }

      <div className={cls.header}>
        <div className={cls.left}>
          <Dropdown menu={{items: menuAvatar}} trigger={['click']}>
            <Avatar size={32} style={{backgroundColor: store.auth.data?.color!}}>{store.auth.data?.username!.charAt(0)}</Avatar>
          </Dropdown>

          <Dropdown menu={{selectedKeys: [store.domain.selectedDomain!], items: menuDomains}} trigger={['click']}>
            <Button shape={'round'} loading={!store.domain.loaded}>{store.domain.loaded && store.domain.currentDomain().name}</Button>
          </Dropdown>

          <Link to={INDEX}><img src={img} alt={'Saturn Logo'}/></Link>
        </div>
        <div className={cls.right}>
          {filters}
        </div>
      </div>
    </header>
  )
})