export interface IStatus {
  name: string
  color?: string
  bgColor?: string
  visible: boolean
  focus?: boolean
}

export const status:IStatus[] = [
  {name: 'New', visible: true},
  {name: 'Selected', color: '#000', bgColor: '#9B9B9B', visible: true},
  {name: 'Focus', color: '#000', bgColor: '#F8E71C', visible: true, focus: true},
  {name: 'Awaiting', color: '#000', bgColor: '#E8178A', visible: true},
  {name: 'Completed', color: '#000', bgColor: '#7ED321', visible: true},
  {name: 'Archive', color: '#000', bgColor: '#68B2F8', visible: false},
]

// [
//   {"name": "New", "visible": true},
//   {"name": "Selected", "color": "#000", "bgColor": "#9B9B9B", "visible": true},
//   {"name": "Focus", "color": "#000", "bgColor": "#F8E71C", "visible": true, focus: true},
//   {"name": "Awaiting", "color": "#000", "bgColor": "#E8178A", "visible": true},
//   {"name": "Completed", "color": "#000", "bgColor": "#7ED321", "visible": true},
//   {"name": "Archive", "color": "#000", "bgColor": "#68B2F8", "visible": false}
// ]