import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined, LeftOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { goToTask } from 'routes';

import { IFormTask } from '../../components/Task/TaskForm';
import { useCreateTaskMutation, useTaskQuery, useUpdateTaskMutation } from '../../generated/graphql';
import { Store } from '../../model/store/Store';

import { TaskForm } from './TaskForm';

import cls from './Task.module.scss';

export const Task = observer(() => {

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation()
  const store = useInstance(Store);

  const query = useTaskQuery({
    variables: {
      id: id
    },
    pollInterval: 2000,
    skip: id === 'new',
  });

  const [createTask] = useCreateTaskMutation();
  const [updateTask, updateTaskData] = useUpdateTaskMutation();

  const goBack = () => {
    navigate(-1)
  }

  const onMutateTask = async (values: IFormTask) => {

    if (id !== 'new') {
      await updateTask({
        variables: {
          id: id!,
          data: {
            name: values.name,
            status: values.status,
            company: values.client,
            epic: values.epic,
            users: values.users,
            content: values.content
          }
        },
        onCompleted: () => {
          query.refetch();
        }
      });
    } else {
      if (values.name) {
        await createTask({
          variables: {
            data: {
              name: values.name,
              content: values.content,
              status: values.status,
              company: values.client,
              epic: values.epic,
              users: values.users,
              domain: store.domain.selectedDomain
            }
          },
          onCompleted: (data) => {
            navigate(goToTask(data.createTask?.data?.id!), {replace: true});
          }
        });
      }
    }
  };

  const debounceOnMutateTask = _.debounce(onMutateTask, 1000);

  const onUpdate = (values: IFormTask) => {
    debounceOnMutateTask(values);
  };

  let initialData:IFormTask = {
    name: undefined,
    client: location.state?.client || undefined,
    epic: location.state?.epic || undefined,
    status: location.state?.status || 'New',
    content: [],
    users: [store.auth.id!]
  };
  if (id !== 'new') {
    initialData = {
      name: query.data?.task?.data?.attributes?.name,
      client: query.data?.task?.data?.attributes?.company?.data?.id!,
      epic: query.data?.task?.data?.attributes?.epic?.data?.id!,
      status: query.data?.task?.data?.attributes?.status!,
      content: query.data?.task?.data?.attributes?.content!,
      users: _.map(query.data?.task?.data?.attributes?.users?.data, item => (item.id!))
    };
  }

  const onDeleteTask = async () => {
    await updateTask({
      variables: {
        id: id!,
        data: {
          enabled: false
        },
      },
      onCompleted: () => {
        goBack()
      }
    })  }

  const menu = {
    items: [
      {
        key: 'delete',
        label: t('Delete'),
        danger: true,
        icon: <DeleteOutlined/>,
        onClick: id !== 'new' ? onDeleteTask : goBack,
      },
    ]
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.left}>
        <Button icon={<LeftOutlined />} type={'text'} onClick={goBack}>{t('Back')}</Button>
      </div>
      <div className={cls.page}>
        <div>
          <Dropdown menu={menu} trigger={['click']}>
            <Button icon={<MoreOutlined />} type={'text'} loading={query.loading || updateTaskData.loading}/>
          </Dropdown>
        </div>
        <div className={cls.content}>

          {(id === 'new' || !query.loading) && <TaskForm id={id!} data={initialData} onUpdate={onUpdate}/>}

        </div>
        <div><Button icon={<CloseOutlined />} type={'text'} onClick={goBack} /></div>
      </div>
      <div className={cls.right}>

        <Input.TextArea value={query.data?.task?.data?.attributes?.body!} bordered={false} autoSize={{ minRows: 1, maxRows: 20 }}/>

      </div>
    </div>
  );
});