import React, { useState } from 'react';
import {Outlet} from 'react-router-dom';

import { Header } from '../components/Header/Header';

import cls from './Layout.module.scss'

// interface ILayout {
//   children?: React.ReactNode
// }

export const Layout  = () => {

  const [state, setState] = useState({
    filters: undefined,
  });

  return (
    <div className={cls.layout}>
      <Header filters={state.filters}/>
      <div className={cls.content}>
        <Outlet context={{setLayout: setState}}/>
      </div>
    </div>
  )
}