import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import {
  ClockCircleOutlined, CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  MoreOutlined,
  PlusOutlined, StarOutlined
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Skeleton, Switch } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import { Tag } from '../../components/Board/Tag';
import { IParam, Page } from '../../components/Page/Page';
import {
  Enum_Invite_Status,
  useDeleteInviteMutation,
  useDeleteUserLinkDomainMutation,
  useDomainQuery,
  useInvitesQuery, useUpdateUserLinkDomainMutation
} from '../../generated/graphql';
import { DOMAIN_INVITE, DOMAIN_RENAME } from '../../routes';

import cls from './Domain.module.scss';

export const Domain = observer(() => {

  const { domain } = useInstance(Store);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteInvite] = useDeleteInviteMutation()
  const [deleteLink] = useDeleteUserLinkDomainMutation()
  const [updateLink] = useUpdateUserLinkDomainMutation()

  const query = useDomainQuery({
    variables: { id: domain.selectedDomain! }
  });

  const invites = useInvitesQuery({
    variables: {
      filters: {
        domain: { id: { eq: domain.selectedDomain! } },
        status: { in: ['CREATED_1', 'SENDED_2', 'ERROR_4'] },
      }
    }
  });

  const adminsCount = useMemo(() => (_.filter(query.data?.domain?.data?.attributes?.users?.data, user => (user.attributes?.admin)).length), [query.data?.domain?.data])
  const activeCount = useMemo(() => (_.filter(query.data?.domain?.data?.attributes?.users?.data, user => (user.attributes?.active)).length), [query.data?.domain?.data])

  const onDeleteInvite = async (id: string) => {
    await deleteInvite({
      variables: {id: id},
      onCompleted: () => {
        invites.refetch()
      }
    })
  };

  const data: IParam[] = [
    {
      key: 'status',
      label: t('Status'),
      component: domain.currentDomain().blocked ?
        <Tag data={{ id: 'status', name: t('Blocked'), color: '#E8178A' }} big /> :
        <Tag data={{ id: 'status', name: t('Active'), color: '#7ED321' }} big />
    },
    {
      key: 'active',
      label: t('Active till'),
      component: <span>{dayjs(domain.currentDomain().activeTill).format('DD MMM YYYY')}</span>
    },
    {
      key: 'users',
      label: t('Users'),
      component: <span>{activeCount} / {domain.currentDomain().activeUsers}</span>
    }
  ];

  const onConfirmDelete = async (id: string) => {
    console.log('Wanna delete', id);
    await deleteLink({
      variables: {id: id},
      onCompleted: () => {query.refetch()}
    })
  }

  const onToggleActive = async (id: string, current: boolean) => {
    await updateLink({
      variables: {id: id, data: {active: !current}},
      onCompleted: () => {query.refetch()}
    })
  }

  const onChangeAdmin = async (id: string, admin: boolean) => {
    await updateLink({
      variables: {id: id, data: {admin: admin}},
      onCompleted: () => {query.refetch()}
    })
  }

  return (
    <Page name={t('Workspace')} title={domain.currentDomain().name} control={
      <Dropdown trigger={['click']} menu={{
        items: [{
          key: 'rename',
          label: t('Rename'),
          icon: <EditOutlined />,
          onClick: () => navigate(DOMAIN_RENAME)
        }]
      }}>
        <Button icon={<MoreOutlined />} />
      </Dropdown>
    }>
      <Page.Content>

        <Page.Params data={data} />

        {query.loading && <Skeleton active />}

      </Page.Content>

      <Page.Divider first />

      {_.map(query.data?.domain?.data?.attributes?.users?.data, (user, index) => (
        <React.Fragment key={user.id}>
          <Page.Content>
            <div className={cls.row}>

              <div className={cls.number}>{index + 1}</div>

              <div className={cls.admin}>{user.attributes?.admin && '⭐'}</div>

              <div className={cls.name}>
                <Avatar style={{ backgroundColor: user.attributes?.user?.data?.attributes?.color! }}>
                  {user.attributes?.user?.data?.attributes?.username.charAt(0)}
                </Avatar> {user.attributes?.user?.data?.attributes?.username}
              </div>

              <div className={cls.control}>
                <Switch checked={user.attributes?.active!} disabled={!user.attributes?.active && activeCount >= query.data?.domain?.data?.attributes?.activeUsers!} onChange={() => onToggleActive(user.id!, user.attributes?.active!)}/>
                <Dropdown menu={{
                  items: [
                  ...(user.attributes?.admin === true ? [{
                      key: 'admin',
                      label: t('Remove Administrator rights'),
                      icon: <StarOutlined />,
                      disabled: adminsCount === 1,
                      onClick: () => {onChangeAdmin(user.id!, false)}
                    }] : []),
                  ...(user.attributes?.admin !== true ? [{
                      key: 'admin',
                      label: t('Make an Administrator'),
                      icon: <StarOutlined />,
                      onClick: () => {onChangeAdmin(user.id!, true)}
                    }] : []),
                    {
                    key: 'delete',
                    label: t('Delete'),
                    icon: <DeleteOutlined />,
                    danger: true,
                    disabled: adminsCount === 1 && user.attributes?.admin,
                    onClick: () => {onConfirmDelete(user.id!)}
                  }]
                }} trigger={['click']}>
                  <Button type={'text'} icon={<MoreOutlined />} />
                </Dropdown>
              </div>

            </div>
          </Page.Content>
          <Page.Divider />
        </React.Fragment>
      ))}

      {query.data?.domain?.data?.attributes?.users?.data.length! > 0 && _.map(invites.data?.invites?.data, (invite, index) => (
        <React.Fragment key={invite.id}>
          <Page.Content>
            <div className={cn([cls.row, cls.hided])}>

              <div className={cls.number}>{query.data?.domain?.data?.attributes?.users?.data.length! + index + 1}</div>

              <div className={cls.admin} />

              <div className={cls.name}>
                <Avatar
                  style={{ backgroundColor: '#7037CD' }}>{invite.attributes?.email.charAt(0)}</Avatar> {invite.attributes?.email}
              </div>

              <div className={cls.control}>
                {invite.attributes?.status === Enum_Invite_Status.Created_1 && <ClockCircleOutlined />}
                {invite.attributes?.status === Enum_Invite_Status.Sended_2 && <MailOutlined />}
                {invite.attributes?.status === Enum_Invite_Status.Error_4 && <CloseCircleOutlined />}
                <Dropdown menu={{
                  items: [{
                    key: 'delete',
                    label: t('Delete'),
                    icon: <DeleteOutlined />,
                    danger: true,
                    onClick: () => {
                      onDeleteInvite(invite.id!);
                    }
                  }]
                }} trigger={['click']}>
                  <Button type={'text'} icon={<MoreOutlined />} />
                </Dropdown>
              </div>

            </div>
          </Page.Content>
          <Page.Divider />
        </React.Fragment>
      ))}

      <Page.Content>
        <Button shape={'circle'} icon={<PlusOutlined />} className={cls.plus} onClick={() => navigate(DOMAIN_INVITE)} />
      </Page.Content>
      <Page.Divider />

    </Page>
  );

});
