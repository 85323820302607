import React from 'react';
import {Outlet} from 'react-router-dom';

import { Header } from '../components/Header/Header';

import cls from './Layout.module.scss'

interface ILayoutIndex {
  children?: React.ReactNode
  filters?: React.ReactNode
}

export const LayoutIndex  = ({filters, children}:ILayoutIndex) => (
    <div className={cls.layout}>
      <Header filters={filters}/>
      <div className={cls.content}>
        <Outlet/>
        {children}
      </div>
    </div>
  )