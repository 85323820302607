import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { OutputData } from '@editorjs/editorjs';
import { Divider, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';

import Editor from 'components/Editor/Editor';
import { SelectClient } from 'components/Form/SelectClient/SelectClient';
import { SelectEpic } from 'components/Form/SelectEpic/SelectEpic';
import { SelectStatus } from 'components/Form/SelectStatus/SelectStatus';
import { SelectTeam } from 'components/Form/SelectTeam/SelectTeam';

import { Reports } from '../../components/Report/Reports';
import { Timers } from '../../components/Timer/TImers';

import cls from './TaskForm.module.scss';

export interface IFormTask {
  name?: string,
  status: string,
  client?: string,
  epic?: string,
  users: string[],
  content?: any,
}

export interface ITaskProps {

  id: string
  data: IFormTask;
  onUpdate: (data: IFormTask) => void;
  disabled?: boolean

}

export const TaskForm = observer(({ id, data, onUpdate, disabled }: ITaskProps) => {

  const [editorData, setEditorData] = useState<OutputData>(data.content);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { domain } = useInstance(Store)

  const onChange = (values: IFormTask) => {
    onUpdate({
      ...values,
      content: data
    });
  };

  const onDataChange = () => {
    onUpdate({
      ...form.getFieldsValue(),
      content: editorData
    });
  };

  const onCreateClient = (id: string) => {
    form.setFieldValue('client', id);
    onDataChange();
  };
  const onCreateEpic = (id: string) => {
    form.setFieldValue('epic', id);
    onDataChange();
  };

  const updateEditor = async (data: OutputData) => {
    await setEditorData(data);
    onUpdate({
      ...form.getFieldsValue(),
      content: data
    });
  };


  return (
    <Form form={form} layout={'horizontal'} labelCol={{ span: 5 }} initialValues={data} onFinish={onChange} disabled={disabled}>

      <Form.Item name={'name'} rules={[]}>
        <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} bordered={false} className={cls.name}
                        placeholder={t('Task name')!} onChange={onDataChange} />
      </Form.Item>

      <Form.Item name={'client'} label={t('Client')}>
        <SelectClient bordered={false} onCreate={onCreateClient} onChange={onDataChange} />
      </Form.Item>

      <Form.Item name={'epic'} label={t('Epic')}>
        <SelectEpic bordered={false} onCreate={onCreateEpic} onChange={onDataChange} />
      </Form.Item>

      <Form.Item name={'status'} label={t('Status')}>
        <SelectStatus bordered={false} onChange={onDataChange} />
      </Form.Item>

      <Form.Item name={'users'} label={t('Team')}>
        <SelectTeam bordered={false} onChange={onDataChange} />
      </Form.Item>

      <Divider style={{ margin: '8px 0' }} />

      {(domain.hasFocus() && id !== 'new') &&
        <>
          <Form.Item label={t('In focus')}>
            <Timers taskId={id}/>
          </Form.Item>
          <Divider style={{ margin: '8px 0' }} />
        </>
      }

      {id !== 'new' &&
        <>
          <Form.Item label={t('Reports')}>
            <Reports taskId={id}/>
          </Form.Item>
          <Divider style={{ margin: '8px 0' }} />
        </>
      }

      <Editor data={editorData} onUpdate={updateEditor} />

    </Form>
  );
})